import Link from 'next/link'
import { NotionComponents } from 'react-notion-x'

export const NotionLinkToNextLink: NotionComponents['Link'] = ({
  href,
  as,
  passHref,
  prefetch,
  replace,
  scroll,
  shallow,
  locale,
  ...props
}: any) => (
  <Link
    {...props}
    href={href}
    as={as}
    passHref={passHref}
    prefetch={false}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    locale={locale}
  ></Link>
)
