'use client'
/* eslint-disable @next/next/no-img-element */
import { SharedNavigation } from '@/components/blogTemplates/sharedNavigation'
import RenderChildText from '@/components/notion-related/renderChildText'
import { buildBlogIndexPageSingleBlockCoverUrl } from '@/lib/notion-api/utils'
import { SiteInfo, TemplateUserConfigResp } from '@/lib/types'
import { cn } from '@/lib/utils'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import getBlogPath from '@/lib/utils/getBlogPath'
import { PlusIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { getTextContent } from 'notion-utils'
import { ReactNode } from 'react'
import TimelineBlogFooter from './timelineBlogFooter'

const TimelineBlogIndexPage: React.FC<{
  resp: SiteInfo
  config: TemplateUserConfigResp
}> = ({ resp, config }) => {
  return (
    <div className="w-full">
      <div className="w-full border-b border-slate-200 text-black">
        <div className="relative mx-auto max-w-5xl py-12 px-6 ">
          <h1 className="m-0 font-sans text-4xl font-medium leading-10 tracking-tighter">
            <RenderChildText properties={resp.title} />
          </h1>
          {resp.description && (
            <p className="mt-2 max-w-lg  text-black/70">
              <RenderChildText properties={resp.description} />
            </p>
          )}
          <SharedNavigation
            navigation={config.navigation}
            className="text-black md:pt-12 pt-4 justify-start static md:absolute"
          />
        </div>
      </div>

      <div className="mx-auto max-w-5xl overflow-hidden px-6">
        {resp.items.map((article, idx) => {
          const imageUrl = buildBlogIndexPageSingleBlockCoverUrl(
            article.properties.cover,
            article.id
          )
          return (
            <SingleTimeline
              key={article.id}
              isLast={idx === resp.items.length - 1}
              date={formatNotionDate(article.properties.date, 'MMM DD, YYYY')}
              href={getBlogPath(article.properties.slug)}
              imgUrl={imageUrl}
              title={getTextContent(article.properties.page)}
              description={
                <RenderChildText
                  properties={article.properties.description || []}
                />
              }
            />
          )
        })}
      </div>
      <TimelineBlogFooter />
    </div>
  )
}
export default TimelineBlogIndexPage

interface SingleTimelineProps {
  date: string
  href: string
  imgUrl: string
  title: string
  description: ReactNode | undefined
  isLast: boolean
}
const SingleTimeline = (props: SingleTimelineProps) => {
  return (
    <div className="pt-10">
      <div className="md:flex">
        <section className="relative flex items-start md:min-w-[280px] md:justify-end">
          <div className="z-10 flex items-center pb-5">
            <Link prefetch={false} href={props.href} as={props.href} passHref>
              <div className="block cursor-pointer text-stone-500 hover:text-black">
                {props.date}
              </div>
            </Link>
            <div className="ml-8 mr-16 hidden md:block">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500">
                <PlusIcon className="h-5 w-5  text-white" aria-hidden="true" />
              </div>
            </div>
          </div>
          <span
            className="absolute hidden translate-x-[-79px] border-r-2 border-slate-200 md:block"
            style={{ height: 'calc(100% + 54px)' }}
          />
        </section>

        <div
          className={cn(
            'border-slate-200 pb-8 w-full',
            props.isLast ? '' : 'border-b'
          )}
        >
          <Link prefetch={false} href={props.href} as={props.href} passHref>
            <div className="w-full aspect-[16/9] overflow-hidden rounded-lg ">
              {props.imgUrl ? (
                <img
                  className="object-cover transition-all duration-500 hover:scale-110"
                  width={1600}
                  height={900}
                  src={props.imgUrl}
                  loading="lazy"
                  alt=""
                />
              ) : (
                <div className="relative pt-[56%] w-full bg-gray-400" />
              )}
            </div>
          </Link>

          <h3 className="pt-6 pb-3">
            <Link href={props.href} as={props.href} passHref prefetch={false}>
              <div className="text-2xl font-semibold leading-9 tracking-tight text-black underline-offset-4 hover:underline">
                {props.title}
              </div>
            </Link>
          </h3>
          <p className="mt-4 text-base font-normal">{props.description}</p>
        </div>
      </div>
    </div>
  )
}
